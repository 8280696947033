import {
  Button as MDBBtn,
  Col as MDBCol,
  Row as MDBRow,
  Modal as MDBModal,
  ModalBody as MDBModalBody,
  ModalFooter as MDBModalFooter,
  ModalHeader as MDBModalHeader,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import React, { useEffect } from "react"; // { useRef, useState } // { useState }
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import baseURL from "@config/baseURL";
// import { lanuageArr, TO_TRAIN_MEMORY } from "@helpers/lanuages";
import { setSentences } from "@store/sentences/action";
import { AlertMessage } from "../Alert";
import successImg2 from "@assets/icons/success.png";
import arrowDownImg from "@assets/icons/arrow-down.svg";
import { blinkAnimation } from "@helpers";
import { Link } from "react-router-dom";

import firebase from "@config/firebaseConfig";

// const Tab = ({ title, active, setActive }) => (
//   <MDBBtn
//     className={`rounded-pill overflow-hidden px-4 py-2 btn-black ${
//       active === title ? "" : "non-active"
//     } overflow-hidden text-lowercase`}
//     data-title={title}
//     data-mdb-ripple-duration="0"
//     onClick={async (e) => {
//       await blinkAnimation(e);
//       setActive(title);
//     }}
//   >
//     {title}
//   </MDBBtn>
// );

const firestore = firebase.firestore;
export default function CreateLink({
  editPageState,
  setEditPageState,
  id,
  URLload,
  shareSet,
  isCollectionAuther,
  handleUpdate,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [tab, setTab] = useState("quick link");
  const { user, isAuth } = useSelector((s) => s.auth);
  const { collections } = useSelector((s) => s.collections);
  console.log("Id", id);

  const [setList, setSetList] = React.useState([]);

  useEffect(() => {
    if (id !== undefined && id !== "") {
      getSetsFromCollection();
    }
  }, [id]);

  const getSetsFromCollection = async () => {
    console.log("getSetsFromCollection");
    setSetList([]);
    await firestore()
      .collection("sets")
      .where("collectionName", "==", editPageState.collectionName)
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        const mySet = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          mySet.push({ ...doc.data(), id: doc.id });
        });
        setSetList(mySet);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        setSetList([]);
      });
  };
  return (
    <>
      <div className="my-4">
        {/* <h5 className="text-center mb-5">Create a link to save this set.</h5> */}

        <div className="g-3 row">
          <>
            {/* <div className="col-12">
              <h4 className="text-center">
                {
                  editPageState.code.split("\n").filter((e) => e.length > 0)
                    .length
                }{" "}
                <b>({editPageState.title ? editPageState.title : "title"})</b>
              </h4>
            </div> */}
            <div className="col-12">
              <section className="position-relative d-flex align-items-center">
                <input
                  type="text"
                  className="custom-input w-100 bg-transparent form-control border-dark text-dark shadow-none fst-italic text-center"
                  placeholder="title*"
                  required
                  value={editPageState.title}
                  disabled={!isAuth}
                  // disabled={editPageState.shareUrl && true}
                  onChange={(e) => {
                    console.log("e.currentTarget.value", e.currentTarget.value);
                    setEditPageState({
                      ...editPageState,
                      title: e.currentTarget.value,
                    });
                  }}
                />

                {id && (
                  <div
                    className="position-absolute cursor-pointer"
                    style={{
                      right: "-2rem",
                    }}
                  >
                    <DropdownButton
                      drop={"down"}
                      variant="secondary"
                      bsPrefix={`w-100 text-center m-0 p-0 border-0 outline-0 delete-dropdown bodyColor`}
                      title={
                        <>
                          <img
                            src={arrowDownImg}
                            alt="Arrow Down"
                            width={16}
                            height={16}
                          />
                        </>
                      }
                    >
                      {setList.map((data, i) => (
                        <Dropdown.Item
                          className={`${
                            data.id === id ? "bg-dark text-bodyColor" : ""
                          } ${
                            i < setList.length - 1
                              ? "border-bottom border-dark"
                              : ""
                          }`}
                          // disabled={data.id === id}
                          // onClick={() => navigate(`/${data.id}`)}
                          onClick={
                            data.id === id
                              ? () => {}
                              : () => navigate(`/${data.id}`)
                          }
                        >
                          {data.lines || (data.sentences || []).length}{" "}
                          <b>{data.titleDefault || "english sentence"} </b>
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                )}
              </section>
            </div>
            {isAuth && (
              <div className="col-12">
                <select
                  className="custom-select w-100 rounded-3 h-100 bg-transparent border-dark text-dark shadow-none fst-italic text-center form-select"
                  id="collectionName"
                  name="collectionName"
                  required
                  defaultValue={editPageState.collectionName}
                  onChange={(e) => {
                    console.log("Collection Value", e.currentTarget.value);
                    setEditPageState({
                      ...editPageState,
                      collectionName: e.currentTarget.value,
                    });
                  }}
                  disabled={!isAuth}
                >
                  <option value="">Select Collection</option>
                  {collections.map((data) => {
                    return (
                      <option value={data.id} key={data.id}>
                        {data.name} {data.isBig && " (Big)"}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="col-12">
              <MDBBtn
                className="rounded-pill overflow-hidden px-5 py-2 btn-black overflow-hidden text-capitalize"
                data-title={
                  (editPageState.shareUrl || isCollectionAuther) && isAuth
                    ? "Update It"
                    : "save this set"
                }
                data-mdb-ripple-duration="0"
                onClick={async (e) => {
                  await blinkAnimation(e);
                  editPageState.shareUrl || isCollectionAuther
                    ? handleUpdate()
                    : shareSet();
                }}
                // disabled={
                //   (editPageState.title === "" ||
                //     editPageState.language === "" ||
                //     editPageState.userName === "") &&
                //   tab !== "quick link"
                // }
                disabled={
                  editPageState.title === "" ||
                  editPageState.collectionName === "" ||
                  !isAuth
                }
              >
                {(editPageState.shareUrl || isCollectionAuther !== "") && isAuth
                  ? "Update It"
                  : "save this set"}
              </MDBBtn>
            </div>
            {!isAuth && (
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to="/login"
                  className="text-body text-decoration-none fst-italic"
                >
                  log in to save this set
                </Link>
              </div>
            )}

            {/* <MDBCol sm="6">
              <input
                type="text"
                id="userName"
                name="userName"
                className="custom-input w-100 bg-transparent form-control border-dark text-dark shadow-none fst-italic text-center"
                placeholder="name*"
                required
                value={editPageState.userName}
                disabled={editPageState.shareUrl && true}
                onChange={(e) => {
                  setEditPageState({
                    ...editPageState,
                    userName: e.currentTarget.value,
                  });
                }}
              />
            </MDBCol>
            <MDBCol sm="6">
              <select
                className="custom-select w-100 rounded-3 h-100 bg-transparent border-dark text-dark shadow-none fst-italic text-center"
                id="language"
                name="language"
                required
                value={editPageState.language}
                disabled={!!editPageState.shareUrl}
                onChange={(e) => {
                  setEditPageState({
                    ...editPageState,
                    language: e.currentTarget.value,
                  });
                }}
              >
                <option value="language">language *</option>
                {lanuageArr.map((language) => {
                  return (
                    <option value={language} key={language}>
                      {language}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
            <MDBCol sm="12">
              <div
                data-tip="You can add your social network here"
                className="d-flex align-items-center border border-dark rounded-3 px-3"
              >
                <span className="fst-italic">https://</span>
                <select
                  id="socialPlatform"
                  className="custom-select bg-transparent border-0 text-dark shadow-none fst-italic py-0 "
                  style={{
                    width: "max-content",
                  }}
                  name="socialPlatform"
                  value={editPageState.socialPlatform}
                  disabled={editPageState.shareUrl && true}
                  onChange={(e) => {
                    setEditPageState({
                      ...editPageState,
                      socialPlatform: e.currentTarget.value,
                    });
                  }}
                >
                  <option value="facebook">facebook</option>
                  <option value="twitter">twitter</option>
                  <option value="instagram">instagram</option>
                  <option value="tiktok">tiktok</option>
                  <option value="pinterest">pinterest</option>
                  <option value="snapchat">snapchat</option>
                  <option value="youtube">youtube</option>
                </select>
                <span className="fst-italic">.com/</span>
                <input
                  type="text"
                  className="custom-input bg-transparent form-control text-dark shadow-none fst-italic text-start px-0 border-0"
                  placeholder="userName"
                  aria-label="userName"
                  disabled={editPageState.shareUrl && true}
                  value={editPageState.socialLinkName}
                  onChange={handleSocialLink}
                />
              </div>
            </MDBCol> */}
          </>
          {/* )} */}
          {/* <div size="12" className="text-center">
            {URLload ? (
              <MDBBtn
                data-mdb-ripple-duration="0"
                className="rounded-pill btn-outline-black text-lowercase"
              >
                <i className="fa fa-spinner fa-spin px-5"></i>
              </MDBBtn>
            ) : (
              <>
                {editPageState.shareUrl !== "" ? (
                  <CopyToClipboard
                    text={`${baseURL}${editPageState.shareUrl}`}
                    onCopy={() => {
                      AlertMessage({
                        message: "Link copied",
                      });
                    }}
                    data-tip={`${baseURL}${editPageState.shareUrl}`}
                  >
                    <MDBBtn
                      className="rounded-pill overflow-hidden px-0 btn-black text-lowercase"
                      data-tip="Create a link to this set if you want to share it with someone."
                      data-title="Copy the link"
                      onClick={(e) => {
                        blinkAnimation(e);
                      }}
                    >
                      Copy the link
                    </MDBBtn>
                  </CopyToClipboard>
                ) : (
                  <div>
                    <div
                      className="d-inline-block position-relative"
                      data-tip="Create a link to this set if you want to share it with someone."
                    >
                      <MDBBtn
                        className="rounded-pill overflow-hidden px-3 btn-black overflow-hidden text-lowercase"
                        data-title="Create a link"
                        data-mdb-ripple-duration="0"
                        onClick={async (e) => {
                          await blinkAnimation(e);
                          shareSet();
                        }}
                        disabled={
                          (editPageState.title === "" ||
                            editPageState.language === "" ||
                            editPageState.userName === "") &&
                          tab !== "quick link"
                        }
                      >
                        Create a link
                      </MDBBtn>
                    </div>
                    <p className="mt-4 font-weight-bold">
                      Mind the kids! We will delete all public links with
                      inappropriate content!
                    </p>
                  </div>
                )}
              </>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
