import {
  Button,
  // ButtonGroup,
  Col,
  Container,
  Col as MDBCol,
  Container as MDBContainer,
  Row as MDBRow,
  Row,
} from "react-bootstrap";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { CiMaximize1, CiMinimize1 } from "react-icons/ci";
import Fade from "react-reveal/Fade";
import { getFontSize } from "./LearningSession/LearningSession";
import { setSentences } from "@store/sentences/action";
import {
  FaCaretLeft,
  FaCaretRight,
  FaPauseCircle,
  FaPlay,
  FaPlayCircle,
} from "react-icons/fa";
import firebase from "@config/firebaseConfig";
import { toast } from "react-hot-toast";
import ReactTooltip from "react-tooltip";
import { IconButton, Tooltip } from "@mui/material";
import collectionActions from "@store/collections/action";
import {
  addSVG,
  circleEmptyIcon,
  closeSVG,
  deleteSVG,
  eyeSVG,
  fullScreenSVG,
  replaySVG,
  translateLanguageSVG,
} from "@components/svgComponents/Svgs";
import { CircleIcon } from "@components/LearnPageComponents/TooltipSection";
import { ProgressBarComponent } from "@components/ProgressBarComponent";
import { blinkAnimation } from "@helpers/index";

const firestore = firebase.firestore;

let before;
let after;
const TIME_PER_ANIMATION = 500;

const autoPlayOptions = [
  {
    name: "Off",
    value: 0,
  },
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
  {
    name: "5",
    value: 5,
  },
];
export default function Slides() {
  const [active, setActive] = useState("sentence");
  const [first, setFirst] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [color, setColor] = useState("black");
  const [showText, setShowText] = useState(true);
  const [showCountSection, setShowCountSection] = useState(false);
  // const [moveOn, setMoveOn] = useState(false);
  const [isShuffledOn, setIsShuffledOn] = useState(false);
  const [useEffectCall, setUseEffectCall] = useState(false);
  const [autoPlayIndex, setAutoPlayIndex] = useState(0);
  const [stopAutoPlay, setStopAutoPlay] = useState(false);

  const flashScreen = useRef(null);
  // const incRef = useRef(null);
  const [showFlashScreen, setShowFlashScreen] = useState(false);
  const [flashTimer, setFlashTimer] = useState(300);

  const [lastKey, setLastKey] = useState(null);
  const [lastTime, setLastTime] = useState(0);

  const handleFlash = useCallback(() => {
    flashScreen.current && flashScreen.current.classList.add("animate");
    // incRef.current = 1000;
    setShowFlashScreen(true);
    setFlashTimer((prev) => prev + 300);
    setTimeout(() => {
      setShowFlashScreen(false);
    }, flashTimer);
  }, [flashTimer]);

  const notActive = useMemo(
    () => (active === "sentence" ? "subtitle" : "sentence"),
    [active]
  );
  console.log("firstfirst", first);
  const toggleFullScreen = () => {
    // Toggle the fullscreen state
    setIsFullScreen(!isFullScreen);

    // Enter or exit fullscreen mode based on the current state
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    // Add event listener for F11 key
    const handleKeyDown = (event) => {
      if (event.key === "F11") {
        toggleFullScreen();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Remove event listener on component unmount
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen]);
  // redux hooks
  let { sentences, id, isAuthor } = useSelector((state) => state.sentences);
  let { sets, collectionId } = useSelector(
    (state) => state.collections.targetCollection
  );

  const dispatch = useDispatch();
  const slideSentence = useMemo(
    () => [
      ...sentences,
      ...(sentences.some((item) => item.sentence === "The End")
        ? []
        : [
            {
              sentence: "The End",
              subtitle: "The End",
              mastered: null,
              tried: false,
              isEnd: true,
            },
          ]),
    ],
    [sentences]
  );

  const shuffleFun = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const toogleShuffle = (shuffle) => {
    setIsShuffledOn(shuffle);
    if (shuffle) {
      slideSentence.splice(0, slideSentence.length);
      slideSentence.push(...[...shuffleFun([...sentences])], {
        sentence: "The End",
        subtitle: "The End",
        mastered: null,
        tried: false,
        isEnd: true,
      });
    } else {
      slideSentence.splice(0, slideSentence.length);
      slideSentence.push(...sentences, {
        sentence: "The End",
        subtitle: "The End",
        mastered: null,
        tried: false,
        isEnd: true,
      });
    }
    console.log("After shuffle", slideSentence);
  };

  // routers
  const navigate = useNavigate();

  // useStates
  const [isTestStart, setIsTestStart] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // refs
  const fadeScreen = useRef(null);
  const nextSlide = useCallback(() => {
    setFlashTimer(300);
    if (currentIndex < slideSentence.length - 1) {
      // setFirst(false);
      // setCurrentIndex(null);
      // clearTimeout(before);
      // let i = currentIndex + 1;
      // before = setTimeout(() => {
      //   setCurrentIndex(i);
      // }, TIME_PER_ANIMATION);

      // Old Functionality
      let firstItem = !first;
      if (slideSentence[currentIndex]?.[notActive]) {
        setFirst(firstItem);
        if (!firstItem) setCurrentIndex(null);
      } else {
        setCurrentIndex(null);
      }
      clearTimeout(before);
      let i = currentIndex + 1;
      before = setTimeout(() => {
        if (!slideSentence[currentIndex]?.[notActive]) {
          setCurrentIndex(i);
        } else if (!firstItem) setCurrentIndex(i);
      }, TIME_PER_ANIMATION);
    } else {
      navigate(-1);
    }
  }, [currentIndex, first, navigate, slideSentence, notActive]);
  const perviousSlide = useCallback(() => {
    setFlashTimer(300);
    if (currentIndex !== 0 && !first) {
      // setFirst(false);
      // setCurrentIndex(null);
      // clearTimeout(before);
      // let i = currentIndex - 1;
      // before = setTimeout(() => {
      //   setCurrentIndex(i);
      // }, TIME_PER_ANIMATION);
      // Old Functionality
      let firstItem = !first;
      if (slideSentence[currentIndex]?.[notActive]) {
        setFirst(firstItem);
        if (!firstItem) setCurrentIndex(null);
      } else {
        setCurrentIndex(null);
      }
      clearTimeout(after);

      let i = currentIndex - 1;
      if (firstItem) setCurrentIndex(null);
      after = setTimeout(() => {
        if (!slideSentence[currentIndex]?.[notActive]) {
          setCurrentIndex(i);
        } else if (firstItem) setCurrentIndex(i);
      }, TIME_PER_ANIMATION);
    } else {
      if (first) setFirst(false);
    }
  }, [currentIndex, first, slideSentence, notActive]);
  const removeSentence = useCallback(() => {
    if (slideSentence.length > 0) {
      const updatedSentences = [...slideSentence];
      updatedSentences.splice(currentIndex, 1);
      dispatch(setSentences(updatedSentences));
      setFirst(false);
    }
  }, [currentIndex, dispatch, slideSentence]);
  const handleMoveSet = useCallback(async (setId) => {
    const mySentance = slideSentence[currentIndex];
    console.log("slideSentence currentIndex", mySentance);
    await firestore()
      .collection("sets")
      .doc(setId)
      .get()
      .then(async (data) => {
        const firebaseData = data.data();
        const myData = [
          ...firebaseData.sentences,
          {
            link: mySentance.link ?? "",
            sentence: mySentance.sentence ?? "",
            subtitle: mySentance.subtitle ?? "",
          },
        ];

        let myRemaingSentance = [];
        slideSentence.forEach((item, index) => {
          if (index !== currentIndex && index !== slideSentence.length - 1) {
            myRemaingSentance.push({
              link: item.link ?? "",
              sentence: item.sentence ?? "",
              subtitle: item.subtitle ?? "",
            });
          }
        });

        console.log("myRemaingSentance", myRemaingSentance);
        setFirst(false);

        await firestore()
          .collection("sets")
          .doc(id)
          .update({
            sentences: myRemaingSentance,
            lines: myRemaingSentance.length,
          })
          .then(() => {
            dispatch(setSentences(myRemaingSentance));
          });

        await firestore()
          .collection("sets")
          .doc(setId)
          .update({
            sentences: myData,
            lines: myData.length,
          })
          .then(() => {
            toast.success("Sentence moved successfully!");
          })
          .catch((error) => {
            console.error("Error updating document:", error);
          });
      });
  }, [currentIndex, dispatch, id, slideSentence]);
  var onkeypress = useCallback(
    /**
     *
     * @param {KeyboardEvent} e
     * @returns
     */
    (e) => {
      const currentKey = e.key;
      const currentTime = new Date().getTime();

      if (currentKey === lastKey && currentTime - lastTime < 500) {
        console.log("Duplicate key press prevented:", currentKey);
        return;
      }

      setLastKey(currentKey);
      setLastTime(currentTime);
      if (e.altKey) {
        if (["1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(e.key)) {
          const index = parseInt(e.key) - 1;
          if (sets[index]) {
            handleMoveSet(sets[index].id);
          } else {
            toast.error("Set not found!");
          }
        }
      }
      const { keyCode } = e;
      if (keyCode === 27 && isTestStart) {
        navigate(-1);
        return;
      }

      if (isTestStart) {
        if (e.ctrlKey && keyCode === 32) {
          console.log("ctrl + space");
          toogleAutoPlay();
        }
        if (currentIndex < slideSentence.length - 1 && keyCode === 39) {
          console.log("right key pressed");
          nextSlide();
        } else if (keyCode === 37 || keyCode === 8) {
          perviousSlide();
        } else if (keyCode === 13) {
          console.log("Enter key pressed");
          nextSlide();
        } else if (keyCode === 40) {
          if (!first) {
            handleFlash();
          }
        } else if (keyCode === 38) {
          removeSentence();
        } else if (currentIndex === slideSentence.length - 1) {
          navigate(-1);
        }
      } else {
        if (slideSentence.length) {
          setIsTestStart(true);
        } else {
          navigate(-1);
        }
      }
    },
    [
      currentIndex,
      isTestStart,
      slideSentence.length,
      navigate,
      nextSlide,
      perviousSlide,
      handleFlash,
      removeSentence,
      first,
      handleMoveSet,
      lastKey,
      lastTime,
      sets,
    ]
  );
  // Slide URL Qoute
  useEffect(() => {
    let urlSearchQuery = new URLSearchParams(window.location.search).get(
      "quote"
    );
    let persentationType = new URLSearchParams(window.location.search).get(
      "type"
    );
    console.log("ues effect caliing dkcbkdcbkdb");

    if (persentationType === "flashcard" && !useEffectCall) {
      setActive("subtitle");
      toogleShuffle(true);
      // setMoveOn(true);
      if (isAuthor) {
        setEditing(true);
      }
      setUseEffectCall(true);
    }

    if (urlSearchQuery) {
      const searchParamsArray = urlSearchQuery.split("/n");
      searchParamsArray.forEach((data) => {
        if (data) {
          sentences.push({
            sentence: data,
            subtitle: "",
            link: "",
            mastered: null,
            tried: false,
          });
        }
      });
    }
  }, [sentences]);

  // useEffect(() => {
  //   console.log("Calling getCollectionSetsData");
  //   if (moveOn) {
  //     console.log("Calling getCollectionSetsData move on");
  //     dispatch(collectionActions.getCollectionSetsData(collectionId));
  //   }
  // }, [dispatch, collectionId, moveOn]);

  useEffect(() => {
    if (window.innerWidth > 600) {
      window.addEventListener("keydown", onkeypress);
    } else {
      // window.addEventListener("touchstart", ()=>setIsTestStart(true));
    }
    return () => {
      window.removeEventListener("keydown", onkeypress);
      // window.removeEventListener("touchstart", ()=>setIsTestStart(true));
    };
  }, [onkeypress, currentIndex, isTestStart]);

  // methods
  const reverseColor = () => {
    if (
      fadeScreen.current &&
      fadeScreen.current.classList.contains("animate")
    ) {
      setColor("yellow");
      fadeScreen.current.classList.remove("animate");
    } else {
      setColor("black");
      fadeScreen.current.classList.add("animate");
    }
  };
  console.log("COLOR:", color);
  const startSlide = () => {
    if (slideSentence.length) {
      setIsTestStart(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (slideSentence.length === 0) {
      navigate("/app");
    }
  }, [slideSentence, navigate]);
  const handleInputChangeNotActive = (e) => {
    const updatedSentences = [...slideSentence];
    updatedSentences[currentIndex] = {
      ...updatedSentences[currentIndex],
      [notActive]: e.target.value,
    };
    dispatch(setSentences(updatedSentences));
  };
  const handleInputChangeActive = (e) => {
    const updatedSentences = [...slideSentence];
    updatedSentences[currentIndex] = {
      ...updatedSentences[currentIndex],
      [active]: e.target.value,
    };
    dispatch(setSentences(updatedSentences));
  };
  
  // const updateCollection = () => {
  //   firestore()
  //     .collection("sets")
  //     .doc(id)
  //     .get()
  //     .then(async (documentSnapshot) => {
  //       if (documentSnapshot.exists) {
  //         const storeData = documentSnapshot.data();
  //         console.log("found", storeData);
  //         console.log("slideSentence", slideSentence.map(item => ({ ...item, tried: undefined, mastered: undefined })));

  //         const lastSentence = slideSentence[slideSentence.length - 1];
  //         const shouldExcludeLast = lastSentence && lastSentence.subtitle.endsWith('The End') && lastSentence.sentence.endsWith('The End');

  //         // If the last sentence ends with "The End," exclude it
  //         const filteredSentences = shouldExcludeLast
  //           ? slideSentence.slice(0, -1)
  //           : slideSentence;
  //         await firestore().collection("sets").doc(id).update({ sentences: filteredSentences });
  //         toast("Sets updated Successfully!")
  //       } else {
  //         console.log("Document does not exist");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error getting document:", error);
  //     });

  // }
  const [loading, setLoading] = useState(false);

  const updateCollection = async () => {
    try {
      setLoading(true);

      const documentSnapshot = await firestore()
        .collection("sets")
        .doc(id)
        .get();

      if (documentSnapshot.exists) {
        const storeData = documentSnapshot.data();
        console.log("found", storeData);
        console.log(
          "slideSentence",
          slideSentence.map((item) => ({
            ...item,
          }))
        );

        const lastSentence = slideSentence[slideSentence.length - 1];
        const shouldExcludeLast =
          lastSentence &&
          lastSentence.subtitle?.endsWith("The End") &&
          lastSentence.sentence?.endsWith("The End");

        // If the last sentence ends with "The End," exclude it
        const filteredSentences = shouldExcludeLast
          ? slideSentence.slice(0, -1)
          : slideSentence;

        await firestore()
          .collection("sets")
          .doc(id)
          .update({ sentences: filteredSentences });
        // toast("Sets updated Successfully!");
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleMouseEnter = () => {
    setShowCountSection(true);
  };

  const handleMouseLeave = () => {
    setShowCountSection(false);
  };

  const toogleAutoPlay = () => {
    setStopAutoPlay((prev) => !prev);
  };

  // move to next silde after 1 second
  useEffect(() => {
    if (stopAutoPlay) return;
    if (autoPlayOptions[autoPlayIndex].value > 0) {
      if (isTestStart && !slideSentence[currentIndex]?.isEnd) {
        const timer = setTimeout(() => {
          nextSlide();
        }, autoPlayOptions[autoPlayIndex].value * 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [isTestStart, nextSlide, autoPlayIndex, stopAutoPlay]);
  return (
    <>
      {/* <NavbarHeader /> */}
      <div
        className="bodyColor min-vh-100 animate"
        style={{ transition: "all .3s ease-in-out", overflowX: "hidden" }}
        ref={fadeScreen}
      >
        <div className="d-flex justify-content-center align-items-center flex-column min-vh-100">
          {!isTestStart ? (
            <p
              onClick={startSlide}
              className="display-3 fw-bold mb-5"
              style={{
                cursor: "pointer",
              }}
            >
              {/* {window.innerWidth > 600 ? "press anything" : "tap"} to start
                the slide show */}
              start {active !== "subtitle" ? "presentation" : "flashcard"}
            </p>
          ) : (
            <div
              // onMouseLeave={handleMouseLeave}
              // onMouseEnter={handleMouseEnter}
              className="w-100"
            >
              {currentIndex !== slideSentence.length - 1 && (
                <section
                  onMouseLeave={handleMouseLeave}
                  onMouseEnter={handleMouseEnter}
                  className="position-fixed bottom-0 start-0 w-100 d-flex flex-column justify-content-center align-items-center gap-2 p-2"
                  style={{
                    opacity: showCountSection ? "1" : ".1",
                    transition: "all .3s ease-in-out",
                  }}
                >
                  <div className="d-flex justify-content-center algin-items-center gap-2">
                    {editing &&
                      !slideSentence[currentIndex]?.isEnd &&
                      isAuthor && (
                        <>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Remove from this set"}
                          >
                            <Button
                              className="bg-transparent shadow-none text-dark p-0 border-0"
                              onClick={removeSentence}
                            >
                              {deleteSVG({
                                width: "2rem",
                                height: "2rem",
                              })}
                            </Button>
                          </Tooltip>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Add Subtitle Column B"}
                          >
                            <Button
                              className="bg-transparent shadow-none text-dark p-0 border-0"
                              onClick={() => {}}
                            >
                              {addSVG({
                                opacity: 0.5,
                                width: "2rem",
                                height: "2rem",
                              })}
                            </Button>
                          </Tooltip>
                        </>
                      )}

                    {!slideSentence[currentIndex]?.isEnd &&
                      // moveOn &&
                      editing &&
                      isAuthor &&
                      sets
                        .filter((data) => data.id !== id)
                        .map((data, index) => (
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            key={index}
                            title={data.titleDefault || "english sentence"}
                          >
                            <Button
                              className="bodyColor shadow-none text-dark p-0 border-0 fw-bold rounded-circle"
                              style={{
                                width: "2rem",
                                height: "2rem",
                              }}
                              onClick={() => handleMoveSet(data.id)}
                            >
                              {index + 1}
                            </Button>
                          </Tooltip>
                        ))}
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    {autoPlayIndex !== 0 && (
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={
                          // stopAutoPlay ? "start autoPlay" : "pause autoplay"
                          'Press "Ctrl + Space" to start/pause autoplay'
                        }
                      >
                        <button
                          onClick={toogleAutoPlay}
                          className="position-absolute start-0 btn p-0 rounded-circle"
                        >
                          {stopAutoPlay ? (
                            <FaPlayCircle size={32} />
                          ) : (
                            <FaPauseCircle size={32} />
                          )}
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Esc to QUIT"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        onClick={() => navigate(-1)}
                      >
                        {closeSVG()}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Full Screen"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        onClick={toggleFullScreen}
                      >
                        {fullScreenSVG()}
                      </Button>
                    </Tooltip>
                    <div className="d-flex justify-content-center align-items-center g-0">
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Previous"}
                      >
                        <Button
                          className="circular-icons border-0 p-0 rounded-circle bg-transparent"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            perviousSlide();
                          }}
                          disabled={currentIndex === 0}
                        >
                          <FaCaretLeft size={28} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={!first ? "Flash: Arrow Down" : ""}
                      >
                        <IconButton
                          color="primary"
                          className="text-dark p-0"
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await blinkAnimation(e);
                            if (!first) {
                              handleFlash();
                            }
                            // nextSlide();
                            // updateCollection();
                          }}
                        >
                          {circleEmptyIcon({
                            width: "3rem",
                            height: "3rem",
                          })}
                          {/* <CircleIcon
                            size={48}
                            color="inherit"
                            fontSize="inherit"
                          >
                            <span
                              className="text-dark"
                              style={{
                                fontSize: "1rem",
                              }}
                            >
                              {currentIndex != null
                                ? `${currentIndex + 1}/${
                                    slideSentence.length - 1
                                  }`
                                : ""}
                            </span>
                          </CircleIcon> */}
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Next"}
                      >
                        <Button
                          className="circular-icons border-0 p-0 rounded-circle bg-transparent"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            nextSlide();
                            updateCollection();
                          }}
                          disabled={currentIndex === slideSentence.length - 1}
                        >
                          <FaCaretRight size={28} />
                        </Button>
                      </Tooltip>
                    </div>

                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Hide"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        onMouseEnter={() => {
                          setShowText(false);
                        }}
                        onMouseLeave={() => {
                          setShowText(true);
                        }}
                      >
                        {eyeSVG({
                          width: "2rem",
                          height: "2rem",
                        })}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          style: {
                            color: `var(--bgColor)`,
                            background: `var(--text-color)`,
                          },
                        },
                      }}
                      title={"Translate in Google"}
                    >
                      <Button
                        className="bg-transparent shadow-none text-dark p-0 border-0"
                        as="a"
                        href={`https://translate.google.com?text=${slideSentence[currentIndex]?.[active]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {translateLanguageSVG({
                          width: "2rem",
                          height: "2rem",
                        })}
                      </Button>
                    </Tooltip>
                  </div>
                </section>
              )}
              <div className={`floatingButton d-none`}>
                <Row>
                  <Col>
                    <Button
                      onClick={() => navigate(-1)}
                      className="circular-icons icon-dark border-0"
                    >
                      <IoClose size={24} />
                    </Button>
                    {/* <AiFillCloseCircle size={32} /> */}
                  </Col>
                  <Col>
                    <Button
                      onClick={toggleFullScreen}
                      className="circular-icons icon-dark border-0"
                    >
                      {isFullScreen ? (
                        <CiMinimize1 size={24} onClick={toggleFullScreen} />
                      ) : (
                        <CiMaximize1 size={24} onClick={toggleFullScreen} />
                      )}
                    </Button>
                  </Col>
                  {editing &&
                    !slideSentence[currentIndex]?.isEnd &&
                    isAuthor && (
                      // showCountSection &&
                      <>
                        {/* <Button
                            variant="danger"
                            className="slides-action-button rounded text-dark"
                            // disabled={loading}
                            disabled={true}
                            // onClick={updateCollection}
                          >
                            {loading ? "Saving" : "S"}
                          </Button> */}
                        <Col>
                          <Button
                            className="circular-icons icon-dark border-0"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              perviousSlide();
                            }}
                          >
                            <FaCaretLeft size={28} />
                          </Button>
                        </Col>
                        <Col>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Hide the text"}
                          >
                            <Button
                              className="circular-icons icon-dark border-0"
                              onMouseEnter={() => {
                                setShowText(false);
                              }}
                              onMouseLeave={() => {
                                setShowText(true);
                              }}
                            >
                              H
                            </Button>
                          </Tooltip>
                        </Col>
                        <Col>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Translate in Google"}
                          >
                            <Button
                              className="circular-icons icon-dark border-0"
                              as="a"
                              href={`https://translate.google.com?text=${slideSentence[currentIndex]?.[active]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              T
                            </Button>
                          </Tooltip>
                        </Col>

                        <Col>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Remove from this set"}
                          >
                            <Button
                              className="circular-icons icon-dark border-0"
                              onClick={removeSentence}
                            >
                              X
                            </Button>
                          </Tooltip>
                        </Col>
                        <Col>
                          <Button
                            className="circular-icons icon-dark border-0"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              nextSlide();
                              updateCollection();
                            }}
                          >
                            <FaCaretRight size={28} />
                          </Button>
                        </Col>
                      </>
                    )}
                </Row>
              </div>

              <Fade
                opposite
                duration={TIME_PER_ANIMATION}
                when={![null, undefined].includes(currentIndex)}
              >
                <div
                  style={{
                    visibility: ![null, undefined].includes(currentIndex)
                      ? "visible"
                      : "hidden",
                  }}
                  className="d-flex flex-column gap-4 flex-wrap justify-content-center align-items-center"
                >
                  <div
                    style={{
                      visibility: showText ? "visible" : "hidden",
                      opacity: showText ? 1 : 0,
                      transition: "all .3s ease-in-out",
                    }}
                    onClick={() =>
                      !editing || slideSentence[currentIndex]?.isEnd
                        ? nextSlide()
                        : null
                    }
                    className="w-100 d-flex flex-column gap-4 flex-wrap justify-content-center align-items-center"
                  >
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="w-100"
                    >
                      {editing && !slideSentence[currentIndex]?.isEnd ? (
                        <input
                          style={{
                            fontSize: `max(3rem, 1.5rem)`,
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onChange={handleInputChangeActive}
                          onKeyDown={(e) => e.stopPropagation()}
                          className="fw-normal basic-input outline-none shadow-none text-center m-0 w-100"
                          value={slideSentence[currentIndex]?.[active]}
                        />
                      ) : (
                        <h1
                          style={{
                            fontSize: `max(${getFontSize(
                              slideSentence[currentIndex]?.[active]
                            )}rem, 1.5rem)`,
                          }}
                          className="text-center"
                        >
                          {slideSentence[currentIndex]?.[active]}
                        </h1>
                      )}
                    </div>
                    {!slideSentence[currentIndex]?.isEnd && (
                      <ProgressBarComponent
                        currentLength={currentIndex + 1}
                        totalLength={slideSentence.length - 1}
                      />
                    )}
                    {/* {active === "subtitle" &&
                      !slideSentence[currentIndex]?.isEnd && (
                        <div
                          className="col-9"
                          style={{
                            border: "1px solid var(--bgColor)",
                          }}
                        ></div>
                      )} */}
                    {!slideSentence[currentIndex]?.isEnd && (
                      <Fade
                        opposite
                        duration={TIME_PER_ANIMATION / 4}
                        when={first || showFlashScreen}
                      >
                        <div
                          style={{
                            visibility:
                              first || showFlashScreen ? "visible" : "hidden",
                          }}
                          className="w-100 d-flex flex-column gap-4 flex-wrap justify-content-center align-items-center"
                        >
                          {editing ? (
                            <input
                              style={{
                                fontSize: `max(3rem, 1.5rem)`,
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onChange={handleInputChangeNotActive}
                              onKeyDown={(e) => e.stopPropagation()}
                              className="fw-normal basic-input shadow-none text-center m-0 w-100"
                              value={slideSentence[currentIndex]?.[notActive]}
                            />
                          ) : slideSentence[currentIndex]?.link ? (
                            <a
                              href={slideSentence[currentIndex]?.link}
                              target="_blank"
                              rel="noreferrer"
                              // style={{
                              //   fontSize: `max(3rem, 1.5rem)`,
                              // }}
                              style={{
                                fontSize:
                                  active === "subtitle"
                                    ? `max(${getFontSize(
                                        slideSentence[currentIndex]?.[notActive]
                                      )}rem, 1.5rem)`
                                    : `max(3rem, 1.5rem)`,
                              }}
                              className="fw-normal text-center m-0 text-decoration-none"
                            >
                              {slideSentence[currentIndex]?.[notActive]}
                            </a>
                          ) : (
                            <h5
                              // style={{
                              //   fontSize: `max(3rem, 1.5rem)`,
                              // }}
                              style={{
                                fontSize:
                                  active === "subtitle"
                                    ? `max(${getFontSize(
                                        slideSentence[currentIndex]?.[notActive]
                                      )}rem, 1.5rem)`
                                    : `max(3rem, 1.5rem)`,
                              }}
                              className="fw-normal text-center m-0"
                            >
                              {slideSentence[currentIndex]?.[notActive]}
                            </h5>
                          )}
                        </div>
                      </Fade>
                    )}
                  </div>

                  {slideSentence[currentIndex]?.isEnd && (
                    <div className="d-flex justify-content-center align-items-center gap-3 mb-5">
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Esc to QUIT"}
                      >
                        <Button
                          className="bg-transparent shadow-none text-dark p-0 border-0"
                          onClick={() => navigate(-1)}
                        >
                          {closeSVG()}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: `var(--bgColor)`,
                              background: `var(--text-color)`,
                            },
                          },
                        }}
                        title={"Replay"}
                      >
                        <Button
                          className="bg-transparent shadow-none text-dark p-0 border-0"
                          onClick={() => {
                            setCurrentIndex(0);
                            setFlashTimer(300);
                          }}
                        >
                          {replaySVG()}
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                  {/* {editing && !slideSentence[currentIndex]?.isEnd && (
                    <ButtonGroup
                      className={`slides-action-buttons 
                      ${showCountSection ? "fade-in" : "fade-out"}
                      `}
                    >
                      {isAuthor && (
                        <>
                          <Button
                            style={{ backgroundColor: "transparent" }}
                            className="slides-action-button text-dark"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              perviousSlide();
                            }}
                          >
                            <FaCaretLeft size={28} />
                          </Button>
                          <Button
                            variant="danger"
                            className="slides-action-button rounded text-dark"
                            // disabled={loading}
                            disabled={true}
                            // onClick={updateCollection}
                          >
                            {loading ? "Saving" : "S"}
                          </Button>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Hide the text"}
                          >
                            <Button
                              style={{ backgroundColor: "var(--bgColor)" }}
                              className="slides-action-button rounded text-dark"
                              onMouseEnter={() => {
                                setShowText(false);
                              }}
                              onMouseLeave={() => {
                                setShowText(true);
                              }}
                            >
                              H
                            </Button>
                          </Tooltip>
                          <Tooltip
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            title={"Translate in Google"}
                          >
                            <Button
                              style={{ backgroundColor: "var(--bgColor)" }}
                              className="slides-action-button rounded text-dark"
                              as="a"
                              href={`https://translate.google.com?text=${slideSentence[currentIndex]?.[active]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              T
                            </Button>
                          </Tooltip>
                          {isAuthor ? (
                            <Tooltip
                              arrow
                              placement="top"
                              componentsProps={{
                                tooltip: {
                                  style: {
                                    color: `var(--bgColor)`,
                                    background: `var(--text-color)`,
                                  },
                                },
                              }}
                              title={"Remove from this set"}
                            >
                              <Button
                                style={{ backgroundColor: "var(--bgColor)" }}
                                className="slides-action-button rounded text-dark"
                                onClick={removeSentence}
                              >
                                X
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          <Button
                            style={{ backgroundColor: "transparent" }}
                            className="slides-action-button text-dark"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              nextSlide();
                              updateCollection();
                            }}
                          >
                            <FaCaretRight size={28} />
                          </Button>
                        </>
                      )}
                    </ButtonGroup>
                  )} */}
                </div>
                {/* )} */}
              </Fade>
              {/*  */}
              {/* {!(window.innerWidth > 600) && (
                <div className="d-flex flex-row ml-5 mr-5 mt-5">
                  <MDBContainer>
                    <MDBRow style={{ justifyContent: "center" }}>
                      <MDBCol
                        xs={4}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <button
                          data-for="my-tooltip"
                          className="iconButton"
                          onClick={perviousSlide}
                        >
                          <div className="iconButtonText">{"Previous"}</div>
                        </button>
                      </MDBCol>
                      <MDBCol
                        xs={4}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <button onClick={nextSlide} className="iconButton">
                          <div className="iconButtonText">{"Next"}</div>
                        </button>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              )} */}

              {/* {!slideSentence[currentIndex]?.isEnd && moveOn && isAuthor && (
                <div className=" w-100 position-fixed bottom-0 mb-3">
                  <MDBContainer>
                    <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap hover-opacity">
                      {sets
                        .filter((data) => data.id !== id)
                        .map((data, index) => (
                          <Tooltip
                            arrow
                            componentsProps={{
                              tooltip: {
                                style: {
                                  color: `var(--bgColor)`,
                                  background: `var(--text-color)`,
                                },
                              },
                            }}
                            key={index}
                            title={data.titleDefault || "english sentence"}
                          >
                            <button
                              className="circle-box"
                              onClick={() => handleMoveSet(data.id)}
                            >
                              {index + 1}
                            </button>
                          </Tooltip>
                        ))}
                    </div>
                  </MDBContainer>
                </div>
              )} */}
            </div>
          )}
          {(!isTestStart || slideSentence[currentIndex]?.isEnd) && (
            <>
              {/* <p
                onClick={() =>
                  setActive((s) => (s === "sentence" ? "subtitle" : "sentence"))
                }
                className="slideHeading h4 reverseBTN"
                style={{ fontSize: "24px", lineHeight: 1 }}
              >
                first:{" "}
                {active === "subtitle" ? (
                  <span
                    style={{
                      fontWeight: active === "sentence" ? "bold" : "normal",
                    }}
                  >
                    Column B
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: active === "subtitle" ? "bold" : "normal",
                    }}
                  >
                    Column A
                  </span>
                )}
              </p> */}
              {/* <p
                onClick={reverseColor}
                className="slideHeading reverseBTN"
                style={{ fontSize: "24px", lineHeight: 1 }}
              >
                color: {color === "black" ? "Reverse" : "Normal"}
              </p> */}
              <p
                onClick={() => toogleShuffle(!isShuffledOn)}
                className="slideHeading reverseBTN"
                style={{ fontSize: "24px", lineHeight: 1 }}
              >
                shuffle: {isShuffledOn ? "ON" : "OFF"}
              </p>

              {isAuthor && (
                <p
                  onClick={() => setEditing(!editing)}
                  className="slideHeading reverseBTN"
                  style={{ fontSize: "24px", lineHeight: 1 }}
                >
                  editing: {editing ? "ON" : "OFF"}
                </p>
              )}
              {/* {isAuthor && (
                <p
                  onClick={() => setMoveOn(!moveOn)}
                  className="slideHeading reverseBTN"
                  style={{ fontSize: "24px" }}
                >
                  move: {moveOn ? "ON" : "OFF"}
                </p>
              )} */}
              <p
                className="slideHeading reverseBTN"
                style={{ fontSize: "24px", lineHeight: 1 }}
              >
                autplay:{" "}
                <span
                  className={`text-uppercase px-2 `}
                  onClick={() => {
                    if (autoPlayIndex === autoPlayOptions.length - 1) {
                      setAutoPlayIndex(0);
                    } else {
                      setAutoPlayIndex(autoPlayIndex + 1);
                    }
                  }}
                >
                  {autoPlayOptions[autoPlayIndex].name}
                </span>
              </p>
            </>
          )}
        </div>
      </div>
      {/* <FlashSection
        opacity={showFlashScreen ? 1 : 0}
        text={slideSentence[currentIndex]?.[notActive]}
      /> */}
    </>
  );
}

// export const FlashSection = ({ opacity, text }) => {
//   return (
//     <>
//       <div
//         className="animate position-fixed top-0 start-0 w-100 vh-100 bodyColor d-flex justify-content-center align-items-center fadeAnimation"
//         style={{
//           zIndex: 10,
//           transition: "all .3s ease-in-out",
//           opacity,
//           visibility: opacity === 0 ? "hidden" : "visible",
//         }}
//       >
//         <Container>
//           <p
//             className="text-center m-0 text-wrap text-break"
//             style={{
//               fontSize: `max(${getFontSize(text)}rem, 1.5rem)`,
//             }}
//           >
//             {text}
//           </p>
//         </Container>
//       </div>
//     </>
//   );
// };
